import 'styles/styles.scss'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.state) {
    const { disableScrollUpdate } = routerProps.location.state
    return !disableScrollUpdate
  }
}
