exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-industries-js": () => import("./../../../src/templates/industries.js" /* webpackChunkName: "component---src-templates-industries-js" */),
  "component---src-templates-industries-single-js": () => import("./../../../src/templates/industries-single.js" /* webpackChunkName: "component---src-templates-industries-single-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-insights-single-post-builder-js": () => import("./../../../src/templates/insights-single-post-builder.js" /* webpackChunkName: "component---src-templates-insights-single-post-builder-js" */),
  "component---src-templates-insights-single-resource-js": () => import("./../../../src/templates/insights-single-resource.js" /* webpackChunkName: "component---src-templates-insights-single-resource-js" */),
  "component---src-templates-insights-single-simple-post-js": () => import("./../../../src/templates/insights-single-simple-post.js" /* webpackChunkName: "component---src-templates-insights-single-simple-post-js" */),
  "component---src-templates-platform-js": () => import("./../../../src/templates/platform.js" /* webpackChunkName: "component---src-templates-platform-js" */),
  "component---src-templates-platform-single-js": () => import("./../../../src/templates/platform-single.js" /* webpackChunkName: "component---src-templates-platform-single-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-solutions-2-js": () => import("./../../../src/templates/solutions-2.js" /* webpackChunkName: "component---src-templates-solutions-2-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-solutions-single-js": () => import("./../../../src/templates/solutions-single.js" /* webpackChunkName: "component---src-templates-solutions-single-js" */),
  "component---src-templates-terms-js": () => import("./../../../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

